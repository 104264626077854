import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import {
  ProfileContext,
  ProjectsContext,
  ViewContext,
  ViewModalActionTypes,
} from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';
import {
  AlertCategory, KYCStatusTypes, Paths, PROFILE_API, RequestTypes,
} from '../../../constants';

import { KYCAlert, ProjectCard, UserProjectCard } from '../../Molecules';
import { ProfileHandler, ProjectHandler } from '../../../stores/handlers';
import { createStringFromArray, getStatus } from '../../../utils';
import { ReviewResultInterface } from '../../../stores/contexts';
import { httpRequest } from '../../../services';

export const Projects: AppFC = (): AppElement => {
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const { projectsState: { projects = [] } } = useContext(ProjectsContext);
  const {
    viewModal: { info },
    updateViewModal,
  } = useContext(ViewContext);
  const {
    profileState: {
      KYCStatus, reviewResult, isKycPassed, projects: investedProjects,
    },
  } = useContext(ProfileContext);

  const { POST_KYC_START } = PROFILE_API;

  const { updateProfileKYC } = ProfileHandler();
  const noInvestedProjects = projects.filter(({ id: projectId }) => !investedProjects?.find((proj) => proj.id === projectId));
  const status = getStatus(KYCStatus as KYCStatusTypes, reviewResult as ReviewResultInterface);

  const { rejectLabels = [] } = reviewResult || {} as ReviewResultInterface;
  const { getRates } = ProjectHandler();

  useEffect(() => {
    getRates().then();
  }, []);
  const onCloseCLick = () => {
    setAlertIsOpen(false);
    if ((status === KYCStatusTypes.APPROVED) && !isKycPassed) {
      updateProfileKYC({ isKycPassed: true }).catch((error) => {
        console.log('profile kyc update error:', error);
      });
    }
  };

  const kycStart = () => {
    httpRequest({
      data: {},
      url: POST_KYC_START,
      method: RequestTypes.Post,
    })
      .then((res) => {
        if (res.data.token) {
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              isReload: true,
              info: {
                ...info,
                url: `${window.location.origin}${Paths.SumsubKYC}?token=${res.data.token}`,
              },
            },
          });
        }
        if (res.data.verificationLink) {
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              isReload: true,
              info: {
                ...info,
                url: res.data.verificationLink,
              },
            },
          });
        }
      });
  };

  useEffect(() => {
    if (status && status !== KYCStatusTypes.INITIATED && !isKycPassed) {
      setAlertIsOpen(true);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [KYCStatus, isKycPassed]);

  return (
    <>
      <Helmet>
        <title>
          Pantheon Mining -
          Projects
        </title>
      </Helmet>
      <div className="flex flex-col gap-6 h-full">
        {(status === KYCStatusTypes.ON_HOLD) && alertIsOpen && (
          <KYCAlert
            category={AlertCategory.Warning}
            title="KYC Pending"
            description="We are currently reviewing your personal data and will keep you updated on the outcome."
          />
        )}
        {(status === KYCStatusTypes.REJECTED || status === KYCStatusTypes.REJECTED_FINAL) && alertIsOpen && (
          <KYCAlert
            category={AlertCategory.Error}
            title="KYC Rejected"
            description={`The Reason of Rejection: ${createStringFromArray(rejectLabels)}. If you have any questions, contact us at contact@pantheonmining.com.`}
            buttonText={status === KYCStatusTypes.REJECTED ? 'Retry' : ''}
            onButtonClick={kycStart}
          />
        )}

        {(status === KYCStatusTypes.APPROVED) && alertIsOpen && (
          <KYCAlert
            category={AlertCategory.Success}
            title="KYC Passed Successfully"
            description="You have successfully verified your data."
            buttonText="Got It"
            onButtonClick={onCloseCLick}
            onClose={onCloseCLick}
            isClosable
          />
        )}
        {!!investedProjects?.length
        && (
        <>
          <h5 className="text-[1rem] font-semibold leading-[160%]">My Projects</h5>
          <div className="flex flex-col gap-5">
            {investedProjects?.map((project) => (
              <NavLink key={`user_project_${project.id}`} to={`${Paths.Projects}/${project.id}`}>
                <UserProjectCard {...project} />
              </NavLink>
            ))}
          </div>
        </>
        )}
        <h5 className="text-[1rem] text-white font-semibold leading-[160%]">{investedProjects?.length ? 'Other Projects' : 'Projects'}</h5>
        <div className="grid grid-cols-3 max-md:grid-cols-1 max-xl:grid-cols-2 max-2xl::grid-cols-3 gap-5">
          {noInvestedProjects?.map((project, index) => <div key={`project-${index}`}><ProjectCard key={`project-${index}`} {...project} /></div>)}
        </div>
      </div>
    </>
  );
};
