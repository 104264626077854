import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet';
import { AuthHandler } from '../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../interfaces';
import { ForgotPasswordProps } from './types';
import { generateErrorAlert } from '../../../../utils';
import { AlertCategory, AuthPaths, ButtonCategory } from '../../../../constants';

import { Button, Alert } from '../../../Atoms';
import { AppInput } from '../../../Molecules';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is invalid')
    .required('Please enter your email'),
});
export const PasswordForgot: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });

  const { passwordReset } = AuthHandler();

  const onSubmit: SubmitHandler<ForgotPasswordProps> = (data) => {
    setIsLoading(true);
    passwordReset(data)
      .then((res) => {
        setIsLoading(false);
        setAlert(
          <Alert
            className="top-5 z-50"
            category={AlertCategory.Success}
            title={res.data.detail}
            description="Please check your email."
          />,
        );
        setTimeout(() => {
          setAlert(null);
        }, 5000);
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Email sending failed!', setAlert, 'top-5 z-50');
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Pantheon Mining - Forgot Password
        </title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="relative h-full flex flex-col items-center justify-center">
        {alert}
        <h4 className="mb-20">Forgot Password</h4>
        <AppInput
          label="Email Address"
          name="email"
          control={control}
          placeholder="Enter email address"
          error={errors.email}
        />
        <Button className="mt-[3.5rem] w-full" category={ButtonCategory.Filled} isLoading={isLoading}>Reset Password</Button>
        <div className="flex flex-col items-center gap-6 mt-24">
          <span className="font-semibold text-[13px] leading-[100%]">
            Didn&apos;t want to reset password?
          </span>
          <Link to={AuthPaths.SignIn}>
            <Button
              className="w-[200px]"
              category={ButtonCategory.Outlined}
            >
              <h5>Login</h5>
            </Button>
          </Link>
        </div>
      </form>
    </>
  );
};
