import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { NavLink, useSearchParams } from 'react-router-dom';
// import { httpRequest } from '../../../services';

import {
  ProfileContext,
  ProjectsContext,
  ViewContext,
  ProjectsActionTypes,
  ViewModalActionTypes,
} from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';
import { InvestmentControlProps } from './types';
import { ButtonCategory, CurrencyTypes, Paths } from '../../../constants';

import { CurrencyAmountBox } from '../../Molecules';
import { Button, InfoRaw, ProgressBox } from '../../Atoms';
import { MinusIcon, PlusIcon } from '../../Atoms/icons';
import { InfoRawProps } from '../../Atoms/InfoRaw/types';

export const InvestmentControl: AppFC<InvestmentControlProps> = ({
  project,
  spotsAmount,
  setSpotsAmount,
}): AppElement => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const {
    viewModal: {
      isOpen,
      info,
    }, updateViewModal,
  } = useContext(ViewContext);
  const { profileState: { projects: investedProjects, wallet } } = useContext(ProfileContext);
  const { updateProjectsState } = useContext(ProjectsContext);

  const { selectedAccount } = wallet || {};

  const {
    id,
    title,
    description,
    country,
    energySource,
    electricityPrice = 0,
    minerModel,
    pricePerMiner = 0,
    minimumOrderQuantity = 0,
    soldSpots = 0,
    totalSpots = 0,
    wattage,
    analyticsUrl,
  } = project || {};

  const [params] = useSearchParams();
  const miners = params.get('miners');

  const searchPath = `?projectId=${id}${miners ? `&miners=${miners}` : ''}`;

  const investedProject = investedProjects?.find((item) => item.id === id);
  const { boughtSpots = 0 } = investedProject || {};
  const availableSpotsAmount = totalSpots - soldSpots;

  const infoRaws: InfoRawProps[] = [
    {
      label: 'Country',
      value: country,
    },
    {
      label: 'Energy Source',
      value: energySource,
    },
    {
      label: 'Electricity Price per kW/h',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={electricityPrice || '0'}
        currency={CurrencyTypes.EUR}
        decimals={7}
      />,
    },
    {
      label: 'Monthly Electricity Cost *',
      value: !wattage ? '-' : (
        <CurrencyAmountBox
          projectCurrency={project?.currency}
          amount={electricityPrice * 720 * wattage * 0.001 * spotsAmount}
          currency={CurrencyTypes.EUR}
          decimals={7}
        />
      ),
    },
    {
      label: 'Service Fee',
      value: '€ 15 per miner per month',
    },
    {
      label: 'Model of the miner',
      value: minerModel,
    },
    {
      label: 'Order Quantity',
      value: spotsAmount,
    },
    {
      label: 'Price per miner',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={pricePerMiner}
        currency={CurrencyTypes.EUR}
        decimals={5}
      />,
    },
  ];

  const handleInvestClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateViewModal({
      type: ViewModalActionTypes.QuotationDetailsModal,
      payload: {
        title,
        description,
        ref: modalRef,
        info: {
          ...info,
          infoRaws,
          projectCurrency: project.currency,
          projectId: project.id,
          totalPrice: spotsAmount * pricePerMiner,
          pricePerMiner,
          spotsAmount,
          projectName: title,
        },
      },
    });
  };

  const handleAnalyticsClick = () => {
    if (analyticsUrl) {
      window.open(analyticsUrl, '_blank', 'noopener');
    }
  };

  const handleSpotsPlusClick = () => {
    if (spotsAmount < availableSpotsAmount) {
      setSpotsAmount(spotsAmount + 1);
    }
  };

  const handleSpotsMinusClick = () => {
    if (spotsAmount > minimumOrderQuantity) {
      setSpotsAmount(spotsAmount - 1);
    }
  };

  const handleSpotsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    let sanitizedValue = currentValue.replace(/^0+/, '');

    if (Number(sanitizedValue) > availableSpotsAmount) {
      sanitizedValue = availableSpotsAmount.toString();
    }
    if (Number(sanitizedValue) < minimumOrderQuantity) {
      sanitizedValue = minimumOrderQuantity.toString();
    }
    setSpotsAmount(Number(sanitizedValue) || 0);
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = sanitizedValue;
  };
  const handleSpotsBlur = () => {
    if (spotsAmount < minimumOrderQuantity) {
      setSpotsAmount(minimumOrderQuantity);
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (modalRef?.current && !modalRef.current.contains(e.target as Node)) {
      if (isOpen) {
        updateProjectsState({ type: ProjectsActionTypes.SetEnvelopeId, payload: '' });
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="flex flex-col min-w-[280px] h-fit rounded-xl shadow-4xl pb-5 pt-3 bg-white text-black">

      <div className="px-4">
        {investedProject ? (
          <>
            <InfoRaw className="border-none py-0" label="Bought Miners" value={boughtSpots} />
            <InfoRaw className="border-none py-0" label="Money Spent">
              <CurrencyAmountBox projectCurrency={project.currency} amount={boughtSpots * pricePerMiner} currency={selectedAccount?.currency || CurrencyTypes.EUR} decimals={4} />
            </InfoRaw>
          </>
        ) : (
          <div className="flex flex-col gap-1">
            <h4 className="leading-[160%]">Goal</h4>
            <p className="font-medium">{`Sell ${totalSpots} miners to secure your miner on the grid.`}</p>
          </div>
        )}
        <ProgressBox className="py-7" totalAmount={totalSpots} currentAmount={soldSpots} percentIsShown={!!availableSpotsAmount} />
      </div>
      <div className={`px-4 ${availableSpotsAmount ? 'border-t' : ''}`}>
        {!!availableSpotsAmount && (
          <div className="flex flex-col gap-3.5 py-6">
            <span>Miners</span>
            <div className="flex justify-between gap-4 items-center">
              <div className="flex items-center gap-4">
                <div className={`flex justify-center items-center h-8 w-8 rounded-full bg-lightBrown ${spotsAmount > minimumOrderQuantity ? 'opacity-100 cursor-pointer' : 'opacity-40'}`} onClick={handleSpotsMinusClick}><MinusIcon /></div>
                <input className="font-medium" style={{ width: `${spotsAmount.toString().length * 10}px` }} type="number" min={minimumOrderQuantity} onChange={handleSpotsChange} onBlur={handleSpotsBlur} value={spotsAmount} />
                <div className={`flex justify-center items-center h-8 w-8 rounded-full bg-lightBrown ${spotsAmount < availableSpotsAmount ? 'opacity-100 cursor-pointer' : 'opacity-40'}`} onClick={handleSpotsPlusClick}><PlusIcon /></div>
              </div>
              <CurrencyAmountBox projectCurrency={project.currency} className="font-semibold" amount={pricePerMiner * spotsAmount} currency={selectedAccount?.currency || CurrencyTypes.EUR} decimals={4} />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2">
          {!!availableSpotsAmount && (
            <Button
              category={ButtonCategory.Default}
              onClick={handleInvestClick}
              disabled={!availableSpotsAmount}
            >
              <h5>{investedProject ? 'Buy More Miners' : 'Purchase Now'}</h5>
            </Button>
          )}
          <NavLink className="flex items-center justify-center font-medium h-12 hover:underline" to={`${Paths.Calculator}${searchPath}`}>Investment Calculator</NavLink>
          {investedProject && analyticsUrl
                  && (
                  <Button className="border border-coldBrown text-coldBrown hover:bg-coldBrown hover:text-white" onClick={handleAnalyticsClick}>
                    <h5>Analytics</h5>
                  </Button>
                  )}
        </div>
      </div>
    </div>
  );
};
