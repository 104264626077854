import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { AppElement, AppFC } from '../../../interfaces';

import { HistoryRow, HistoryCard } from '../../Molecules';
import { InvoiceHandler } from '../../../stores/handlers';
import { Loader } from '../../Atoms';
import { InvoiceHistoryInterface } from '../../../types/invoicesHistory';

export const InvoicesHistory: AppFC = (): AppElement => {
  const [history, setHistory] = useState<InvoiceHistoryInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getInvoiceHistory } = InvoiceHandler();
  // const orderedInvoices = invoices.sort((a, b) => b.invoiceNumber - a.invoiceNumber);

  useEffect(() => {
    getInvoiceHistory().then((res) => {
      setHistory(res.data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
    // Scroll to the top of the page after a redirect
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Pantheon Mining - Transaction History
        </title>
      </Helmet>
      {isLoading ? <Loader className="self-center w-7 h-full" />
        : (
          <div className="flex flex-col gap-7 h-full">
            <h5 className="text-[1rem] font-semibold leading-[160%]">Transaction History</h5>
            {history.length > 0 ? (
              <>
                <table className="max-md:hidden border-separate border-spacing-y-1 w-full text-sm text-left ">
                  <thead className="font-semibold text-[#A0A0A0] bg-filled ">
                    <tr className="rounded-lg">
                      <th scope="col" className="rounded-l-lg px-6 py-2.5">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-2.5">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-2.5">
                        Total amount
                      </th>
                      <th scope="col" className="px-3 py-2.5">
                        Status
                      </th>
                      <th scope="col" className="rounded-r-lg px-3 py-2.5">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium before:leading-3">
                    {history?.map((item, index) => <HistoryRow key={`invoice_row_${index}`} {...item} className="bg-filled" />)}
                  </tbody>
                </table>
                <div className="md:hidden flex flex-col gap-3">
                  {history?.map((item, index) => <HistoryCard key={`invoice_card_${index}`} {...item} className="bg-filled" />)}
                </div>
              </>
            ) : <div className="self-center text-white/40"> There is no history</div>}
          </div>
        )}
    </>
  );
};
