import { Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AppElement, AppFC } from '../../../interfaces';
import { Paths } from '../../../constants';

import { PantheonIcon } from '../../Atoms/icons';
import { AuthToken } from '../../../services';

export const AuthLayout: AppFC = ({ children }): AppElement => {
  const authToken: AuthToken = new AuthToken();

  if (authToken.getAccessToken() && authToken.getRefreshToken()) {
    return <Navigate to={Paths.Projects} />;
  }

  return (
    <div className="relative h-screen w-full flex items-center max-md:flex-col bg-default">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Link to="https://www.pantheonmining.com/" className="absolute top-8 left-10 max-md:relative max-md:top-0 max-md:py-8 max-md:self-start">
        <PantheonIcon className="" />
      </Link>
      <div className="h-screen flex-[5] bg-authLayout bg-cover max-md:hidden" />
      <div className="h-screen flex-[7] max-md:w-full max-md:flex-1 max-md:h-auto flex flex-col items-center overflow-y-auto">
        {/* { process.env.REACT_APP_MODE === 'production' */}
        {/*       && <Alert className="top-16 z-10 mx-5" category={AlertCategory.Info} description="Platform in live testing, expect disruptions." />} */}
        <div className="w-[calc(20%+220px)] max-w-[360px] min-h-screen max-md:min-h-fit flex-1 flex flex-col">
          {children}
        </div>
      </div>
    </div>
  );
};
