import React, { useState } from 'react';
import {
  Link, useLocation, useNavigate,
} from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput, {
  Country,
  getCountryCallingCode,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';

import { Helmet } from 'react-helmet';
import { AuthHandler } from '../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../interfaces';
import { SignUpFormProps } from './types';
import { AuthPaths, ButtonCategory } from '../../../../constants';
import { getCountries, generateErrorAlert } from '../../../../utils';

import {
  Button, ErrorMessage, Label,
} from '../../../Atoms';
import { AppCheckBox, AppInput, AppSelect } from '../../../Molecules';
// import { Checkbox } from '../../../Atoms/Checkbox';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .test('len', 'Must be more than 1 characters', (val = '') => (val.length >= 2 && val.length <= 40))
    .required('First name is required'),
  lastName: yup
    .string()
    .test('len', 'Must be more than 1 characters', (val = '') => (val.length >= 2 && val.length <= 40))
    .required('Last name is required'),
  email: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  phone: yup
    .string()
    .test('isValidPhoneNumber', 'Invalid phone number', (value) => {
      try {
        return (isValidPhoneNumber(value as string) || isPossiblePhoneNumber(value as string));
      } catch (e) {
        return false;
      }
    })
    .required('Please enter your phone number'),
  countryOfResidence: yup
    .string()
    .required('Please enter your country of residence'),
  password1: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
  password2: yup
    .string()
    .required('Please enter your password')
    .oneOf([yup.ref('password1'), ''], 'Passwords must match')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
  isAgree: yup.boolean().oneOf([true], 'To register an account you must read and accept our terms.').required(),
});

export const SignUp: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState<Country>('NL');
  const [alert, setAlert] = useState<AppElement>();

  const [phoneInputFocused, setPhoneInputFocused] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const { signUp } = AuthHandler();

  console.log('search', search);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      countryOfResidence: '',
      password1: '',
      password2: '',
      isAgree: false,
    },
  });

  const onSubmit: SubmitHandler<SignUpFormProps> = (data) => {
    setIsLoading(true);
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      countryOfResidence = '',
      password1 = '',
      password2 = '',
      isAgree = false,
    } = data;

    const countryCode = getCountryCallingCode(country as Country);
    const countryCodeLength = countryCode.toString().length + 1; // +1 for the plus sign
    const phoneNumberWithoutCountryCode = phone?.slice(countryCodeLength);

    signUp({
      firstName,
      lastName,
      email,
      phoneNumber: phoneNumberWithoutCountryCode,
      countryCode: `+${countryCode}`,
      countryOfResidence,
      password1,
      password2,
      isAgree,
      projectAndMiners: search || '',
    })
      .then(() => {
        navigate(
          AuthPaths.VerifyEmail,
          {
            state: {
              alertMessage: 'Verification email sent!',
              emailAddress: email,
            },
          },
        );
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Sign up failed!', setAlert, 'top-[50%] z-50');
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Pantheon Mining - Sign up
        </title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="relative h-auto flex flex-col items-center justify-center gap-10 py-32 max-md:py-0 max-md:pb-16">
        {alert}
        <div className="flex flex-col gap-6 items-center justify-center w-full">
          <div className="text-center">
            <h4>Create your free account</h4>
            <p className="text-center mt-1">Grow your wealth with Pantheon Bitcoin mining</p>
          </div>
          <AppInput
            label="First Name"
            name="firstName"
            control={control}
            placeholder="Enter first name"
            error={errors.firstName}
          />
          <AppInput
            label="Last Name"
            name="lastName"
            control={control}
            placeholder="Enter last name"
            error={errors.lastName}
          />
          <AppInput
            label="Email"
            name="email"
            control={control}
            placeholder="Enter email address"
            error={errors.email}
          />
          <Label>
            <h5 className={`text-[14px] ${errors.phone ? 'text-error' : ''}`}>Phone Number</h5>
            <Controller
              name="phone"
              control={control}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                  onBlur,
                },
              }) => (
                <PhoneInput
                  className={`phone-input flex gap-2 w-full bg-transparent border rounded-lg ${errors.phone ? `border-error ${phoneInputFocused ? 'border-error border-2' : ''}` : `border-default ${phoneInputFocused ? 'border-lightBrown border-2' : ''}`}  px-4 py-3 focus:outline-none h-12`}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onFocus={() => { setPhoneInputFocused(true); }}
                  onBlur={() => { onBlur(); setPhoneInputFocused(false); }}
                  international
                  defaultCountry={country || 'NL'}
                  onCountryChange={(c) => setCountry(c as Country)}
                  countryCallingCodeEditable={false}
                />
              )}
            />
            {errors.phone && <ErrorMessage className="top-20">{errors.phone.message}</ErrorMessage>}
          </Label>
          <AppSelect
            label="Country of Residence"
            name="countryOfResidence"
            options={getCountries()}
            control={control}
            placeholder="Select country of residence"
            error={errors.countryOfResidence}
          />
          <AppInput
            label="Password"
            name="password1"
            type="password"
            control={control}
            placeholder="Enter password"
            error={errors.password1}
          />
          <AppInput
            label="Confirm Password"
            name="password2"
            type="password"
            control={control}
            placeholder="Repeat password"
            error={errors.password2}
          />
          <AppCheckBox
            name="isAgree"
            control={control}
            label={(
              <div className="flex items-center gap-1 text-[12px] flex-wrap text-white/60">
                <span className="whitespace-nowrap">I’ve read and accept the</span>
                <Link className="underline whitespace-nowrap" to="https://www.pantheonmining.com/terms-and-conditions" target="_blank"> terms of use </Link>
                <span>and</span>
                <Link className="underline whitespace-nowrap" to="https://www.pantheonmining.com/privacy-policy" target="_blank"> privacy policy </Link>
              </div>
              )}
            error={errors.isAgree}
          />
        </div>
        <Button
          className="w-full"
          category={ButtonCategory.Filled}
          type="submit"
          isLoading={isLoading}
        >
          Create Account
        </Button>
        <div className="flex flex-col items-center gap-6">
          <span className="font-semibold text-[13px] leading-[100%]">
            Have an account?
          </span>
          <Link to={AuthPaths.SignIn}>
            <Button
              className="w-[200px]"
              category={ButtonCategory.Outlined}
            >
              <h5>Login</h5>
            </Button>
          </Link>
          <Link className="leading-none" to="https://www.pantheonmining.com/">
            <span className="font-semibold text-[13px] leading-[100%] underline">
              Go Back to the Website
            </span>
          </Link>
        </div>
      </form>
    </>
  );
};
