import React, {
  ChangeEvent, useContext, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../stores';
import { ProfileHandler } from '../../../stores/handlers';

import { AppElement, AppFC } from '../../../interfaces';
import { ProfileDataProps } from './types';
import { ButtonCategory, InputCategory, Paths } from '../../../constants';
import {
  getCities, getCountries, getState,
} from '../../../utils';

import { AppInput, AppSelect, DateSelect } from '../../Molecules';
import {
  Button, ErrorMessage, Label,
} from '../../Atoms';
import { AddIcon } from '../../Atoms/icons';

const validationSchema = yup.object().shape({
  profilePicture: yup
    .string(),
  bitcoinWalletAddress: yup
    .string(),
  telegramID: yup
    .string(),
  country: yup
    .string(),
  state: yup
    .string(),
  city: yup
    .string(),
  zipCode: yup
    .string(),
  address: yup
    .string(),
  year: yup
    .string(),
  month: yup
    .string(),
  day: yup
    .string(),
  email: yup
    .string()
    .email('Email is invalid')
    .required(),
  firstName: yup
    .string().required(),
  lastName: yup
    .string().required(),
  phone: yup
    .string().required(),
});
export const UserProfile: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    profileState: {
      firstName,
      lastName,
      email,
      profilePicture,
      birthdate,
      country,
      city,
      state,
      zipCode,
      address,
      phone,
      telegram,
      bitcoinWalletAddress,
    },
  } = useContext(ProfileContext);

  const { updateViewModal } = useContext(ViewContext);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [selectedImage, setSelectedImage] = useState<File>();

  const { updateProfile } = ProfileHandler();

  const noKYCPassed = true;
  const dob = birthdate?.split('-') || [];

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      country: country || '',
      city: city || '',
      state: state || '',
      zipCode: zipCode || '',
      address: address || '',
      year: dob[0] || '',
      month: dob[1] || '',
      day: dob[2] || '',
      email,
      firstName,
      lastName,
      phone,
      bitcoinWalletAddress: bitcoinWalletAddress || '',
      telegramID: telegram || '',
      profilePicture: profilePicture || '',
    },
  });

  const year = watch('year');
  const month = watch('month');
  const currentCountry = watch('country') || '';
  const currentState = watch('state') || '';

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ProfileDataProps> = (data) => {
    setIsLoading(true);
    const birthDate = data.year && data.month && data.day ? `${data.year}-${data.month}-${data.day}` : '';

    const formData = new FormData();

    selectedImage && formData.append('profile_picture', selectedImage);
    data.country && formData.append('country', data.country);
    data.city && formData.append('city', data.city);
    data.state && formData.append('state', data.state);
    data.zipCode && formData.append('zip_code', data.zipCode);
    data.address && formData.append('address', data.address);
    birthDate && formData.append('birthdate', birthDate);
    data.telegramID && formData.append('telegram', data.telegramID);
    data.bitcoinWalletAddress && formData.append('bitcoin_wallet_address', data.bitcoinWalletAddress);

    updateProfile(formData).then(() => {
      setIsLoading(false);
      window.location.reload();
      navigate(Paths.Projects);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const profilePictureURL = URL.createObjectURL(file);
      setValue('profilePicture', profilePictureURL);
      setSelectedImage(file);
    }
  };

  const handlePasswordChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    updateViewModal({ type: ViewModalActionTypes.PasswordChangeModal, payload: { ref: modalRef } });
  };

  // const handleOutsideClick = (e: MouseEvent) => {
  //   if (modalRef?.current && !modalRef.current.contains(e.target as Node)) {
  //     if (isOpen) {
  //       if (isReload) {
  //         window.location.reload();
  //       }
  //       // updateViewModal({ type: ViewModalActionTypes.Close });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('click', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [isOpen]);

  return (
    <>
      <Helmet>
        <title>
          Pantheon Mining - Profile Info
        </title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-end gap-11 self-center w-[calc(20%+600px)] max-md:w-[calc(15%+300px)] text-white">
        <h4 className="self-center">Profile Info</h4>
        <div className="flex flex-col gap-5 w-fit self-start max-md:items-center max-md:self-center">
          <Label className="max-md:items-center">
            <Controller
              name="profilePicture"
              control={control}
              render={({
                field: {
                  name,
                  value,
                  onBlur,
                },
              }) => (
                <div onBlur={onBlur} className="w-20 h-20 relative">
                  {value ? <img className="w-full h-full object-cover rounded-full" src={value} alt="avatar" />
                    : (
                      <div className="w-full h-full rounded-full bg-filled flex items-center justify-center">
                        <h4>{firstName?.substring(0, 1)}</h4>
                      </div>
                    )}
                  <div className="absolute cursor-pointer flex  items-center justify-center rounded-full w-7 h-7 -bottom-1 -right-1 bg-lightBrown border-4 border-white ">
                    <AddIcon />
                    <input className="absolute bottom-0 opacity-0 w-full h-full cursor-pointer" name={name} type="file" accept="image/*" onChange={handleImageChange} />
                  </div>
                </div>
              )}
            />
            {errors.email && <ErrorMessage className="top-20">{errors.email.message}</ErrorMessage>}
          </Label>
          <div onClick={handlePasswordChange} className="hover:underline cursor-pointer"><h5>Change Password</h5></div>
        </div>
        <div className="flex gap-14 w-full max-md:flex-col max-md:items-center max-md:px-4">
          <div className="flex flex-col items-center gap-8 w-full max-w-[400px]">
            <AppInput
              label="Wallet Address"
              name="bitcoinWalletAddress"
              control={control}
              placeholder="Enter wallet address"
              error={errors.bitcoinWalletAddress}
              copiable
            />
            <AppInput
              label="Email"
              name="email"
              control={control}
              placeholder="Enter email"
              error={errors.email}
              disabled
              blocked
            />
            <AppInput
              label="First Name"
              name="firstName"
              control={control}
              placeholder="Enter first name"
              error={errors.firstName}
              disabled
              blocked
            />
            <AppInput
              label="Last Name"
              name="lastName"
              control={control}
              placeholder="Enter last name"
              error={errors.lastName}
              disabled
              blocked
            />
            <DateSelect
              label="Date of birth"
              control={control}
              errors={errors}
              year={year}
              month={month}
              disabled={!noKYCPassed}
              blocked={!noKYCPassed}
            />
          </div>
          <div className="flex flex-col items-center gap-8 w-full max-w-[400px]">
            <AppInput
              label="Telegram User ID"
              name="telegramID"
              control={control}
              placeholder="Enter telegram User ID"
              error={errors.telegramID}
            />
            <AppSelect
              label="Country"
              name="country"
              options={getCountries()}
              control={control}
              placeholder="Select country"
              error={errors.country}
              disabled
              blocked
            />
            <div className="w-full flex gap-3.5">
              <AppSelect
                label="State"
                name="state"
                options={getState(currentCountry)}
                control={control}
                placeholder="Select state"
                error={errors.state}
                disabled={!noKYCPassed && !!state}
                blocked={!noKYCPassed && !!state}
                className="flex-[2]"
              />
              <AppInput
                label="Zip Code"
                name="zipCode"
                control={control}
                placeholder="Zip code"
                error={errors.zipCode}
                disabled={!noKYCPassed}
                blocked={!noKYCPassed}
                className="flex-[1]"
              />
            </div>
            <AppSelect
              label="City"
              name="city"
              options={getCities(currentCountry, currentState)}
              control={control}
              placeholder="Select City"
              error={errors.city}
              disabled={!noKYCPassed}
              blocked={!noKYCPassed}
            />
            <AppInput
              label="Address"
              name="address"
              control={control}
              placeholder="Enter address"
              error={errors.address}
              disabled={!noKYCPassed}
              blocked={!noKYCPassed}
            />
            <AppInput
              label="Phone Number"
              name="phone"
              category={InputCategory.Phone}
              control={control}
              error={errors.phone}
              disabled={!noKYCPassed}
              blocked={!noKYCPassed}
            />
          </div>
        </div>
        <Button type="submit" className="w-52 mt-7 max-md:mx-4" isLoading={isLoading} category={ButtonCategory.Filled}>Save</Button>
      </form>
    </>
  );
};
